@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: #18191c;
  color: #ffffff;
}

::selection {
  background-color: #7ea3cc;
}

